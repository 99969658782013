import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import zhCN from "antd/es/locale/zh_CN";
import { ConfigProvider } from "antd";
import RouteConfig from "./configs/RouteConfig";
import { validateMessages } from "./configs/config";
import "moment/locale/zh-cn";

ReactDOM.render(
    <ConfigProvider locale={zhCN} autoInsertSpaceInButton={false} form={{ validateMessages }}>
        <RouteConfig />
    </ConfigProvider>,
    document.getElementById("root")
);

serviceWorker.unregister();
