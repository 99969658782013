//管理系统配置文件

const configs = {
    channel: "ADMIN_CHANNEL_WEB_MANAGER",
    appTitle: "大十蓝牙耳机管理平台",
    copyright: "2021 深圳市大十科技有限公司",
    icp: "粤ICP备2021089286号-1",
};

export default configs;

export const defaultFormLayout = {
    itemLayout: {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
            md: { span: 6 },
            xl: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 17 },
            md: { span: 17 },
            xl: { span: 17 },
        },
    },
    submitBtLayout: {
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 17, offset: 6 },
            md: { span: 17, offset: 6 },
            xl: { span: 17, offset: 6 },
        },
    },
    contentsLayout: {
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 19 },
            md: { span: 20 },
            xl: { span: 18 },
        },
    },
};

export const validateMessages = {
    // required: "${name} 不能为空", 这里目录只能显示英文，以后更新
    required: "该项不能为空",
    // ...
};

export const defaultColumnLayout = {
    col2: {
        xs: { span: 12 },
        sm: { span: 12 },
        md: { span: 10 },
        xl: { span: 6 },
        xxl: { span: 4 },
    },
    col3: {
        xs: { span: 8 },
        sm: { span: 8 },
        md: { span: 8 },
        xl: { span: 8 },
        xxl: { span: 6 },
    },
};
